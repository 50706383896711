<template>
  <div class="status-c">
    <fm-title :title-text="actionLabel + ' 条件'" :title-menus="[{key: 'add', label: '新增'}]" @clickTitleMenu="clickTitleMenu">
    </fm-title>
    <div class="c-table">
      <fm-table-new
        :columns="columns"
        :auto-height="true"
        ref="table"
        :data-list="conditionList"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
      </fm-table-new>
    </div>
    <fm-modal title="条件维护" v-model="open" width="600" v-if="open">
      <fm-form label-align="left">
      <!-- <fm-select placeholder="条件类型" absolute filterable :clearable="true" v-model="chooseGroupId">
        <fm-option v-for="item in groupList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
      </fm-select> -->
        <fm-form-item label="数据源">
          <fm-select placeholder="数据源" absolute filterable :clearable="true" v-model="chooseData.dataSource">
            <fm-option v-for="item in sourceDataList.filter(v => v.key !== 'value')" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item v-if="chooseData.dataSource && chooseData.dataSource !== 'value'" label="字段">
          <fm-select placeholder="字段" absolute filterable :clearable="true" v-model="chooseData.dataKey">
            <fm-option v-for="item in chooseData.dataSource === 'user' ? userSourceKeys : dataSourceKeys" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <!-- <fm-form-item v-if="chooseData.dataSource === 'value'" label="值">
          <fm-select placeholder="值" absolute filterable :clearable="true" v-model="chooseData.value">
            <fm-option v-for="item in getSelectData(chooseData.dataSource, chooseData.dataKey)" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item> -->
        <fm-form-item label="判断类型">
          <fm-select placeholder="判断类型" absolute filterable :clearable="true" v-model="chooseData.checkType">
            <fm-option v-for="item in checkTypeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="匹配数据源">
          <fm-select placeholder="匹配数据源" absolute filterable :clearable="true" v-model="chooseData.checkDataSource">
            <fm-option v-for="item in sourceDataList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="字段" v-if="chooseData.checkDataSource && chooseData.checkDataSource !== 'value'">
          <fm-select placeholder="字段" absolute filterable :clearable="true" v-model="chooseData.checkDataKey">
            <fm-option v-for="item in chooseData.checkDataSource === 'user' ? userSourceKeys : dataSourceKeys" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="值" v-if="chooseData.checkDataSource && chooseData.checkDataSource === 'value'">
          <fm-select :multiple="true" placeholder="值" absolute filterable :clearable="true" v-model="chooseData.checkValue">
            <fm-option v-for="item in getSelectData(chooseData.dataSource, chooseData.dataKey)" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="异常说明">
          <fm-input-new v-model="chooseData.errorMsg"></fm-input-new>
        </fm-form-item>
      </fm-form>
      <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">保存</fm-btn>
          <fm-btn @click="open = false">取消</fm-btn>
        </div>
    </fm-modal>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  checkTypeList,
  sourceDataList
} from './lib'

import {
  statusManageRequest
} from '../../api'

export default {
  components: {
    TableActions
  },
  props: {
    actionLabel: { type: String},
    dataSourceKeys: { type: Array},
    userSourceKeys: { type: Array},
    conditionList: { type: Array},
    actionType: { type: String},
    actionId: { type: Number},
  },
  data () {
    return {
      chooseData: {},
      sourceDataList: sourceDataList,
      checkTypeList: checkTypeList,
      open: false
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columns () {
      return [{
        field: 'dataSource',
        title: '数据源',
        render: (h, rowData) => {
          return h('div', this.sourceDataList.find(v => v.key === rowData.dataSource).label)
        }
      },
      {
        field: 'dataKey',
        title: '字段',
        render: (h, rowData) => {
          let text = rowData.dataKey || '-'
          if (rowData.dataKey && ['user', 'data'].includes(rowData.dataSource)) {
            let data = rowData.dataSource === 'user' ? this.userSourceKeys : this.dataSourceKeys
            text = data.find(v => v.key === rowData.dataKey).label
          }
          return h('div', text)
        }
      },
      {
        field: 'checkType',
        title: '判断方式',
        render: (h, rowData) => {
          return h('div', this.checkTypeList.find(v => v.key === rowData.checkType).label)
        }
      },
      // {
      //   field: 'value',
      //   title: '值'
      // },
      {
        field: 'checkDataSource',
        title: '匹配数据源',
        render: (h, rowData) => {
          return h('div', this.sourceDataList.find(v => v.key === rowData.checkDataSource).label)
        }
      },
      {
        field: 'checkDataKey',
        title: '字段',
        render: (h, rowData) => {
          let text = rowData.checkDataKey || '-'
          if (rowData.checkDataKey && ['user', 'data'].includes(rowData.checkDataSource)) {
            let data = rowData.checkDataSource === 'user' ? this.userSourceKeys : this.dataSourceKeys
            text = data.find(v => v.key === rowData.checkDataKey).label
          }
          return h('div', text)
        }
      },
      {
        field: 'checkValue',
        title: '值',
        render: (h, rowData) => {
          let text = rowData.checkValue || '-'
          if (rowData.checkValue && ['user', 'data'].includes(rowData.dataSource) && rowData.dataKey) {
            let data = this.getSelectData(rowData.dataSource, rowData.dataKey)
            let checkValueList = rowData.checkValue.split(',')
            text = data.filter(v => checkValueList.includes(v.key)).map(v1 => v1.label).join(',')
          }
          return h('div', text)
        }
      },
      {
        field: 'errorMsg',
        title: '异常提示'
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions',
        width: 100,
        fixed: 'right',
        search: false,
        export: false
      }]
    }
  },
  methods: {
    clickTitleMenu () {
      this.chooseData = {}
      this.open = true
    },
    async formSubmit () {
      let checkValue = null
      if (this.chooseData.checkValue && this.chooseData.checkValue.length > 0) {
        checkValue = this.chooseData.checkValue.join(',')
      }
      if (!this.chooseData.id) {
        this.chooseData.actionType = this.actionType
        this.chooseData.actionId = this.actionId
        await statusManageRequest.addCondition(Object.assign({}, this.chooseData, {checkValue}))
      } else {
        await statusManageRequest.updateCondition(this.chooseData.id, Object.assign({}, this.chooseData, {checkValue}))
      }
      this.$emit('dataUpdate')
      this.open = false
    },
    async tableAction (parm) {
      this.chooseData = Object.assign({}, parm.data)
      this.chooseData.dataSource = this.chooseData.dataSource || 'value'
      this.chooseData.checkDataSource = this.chooseData.checkDataSource || 'value'
      if (this.chooseData.checkValue) {
        this.chooseData.checkValue = this.chooseData.checkValue.split(',')
      }
      if (parm.action === 'edit') {
        this.open = true
      } else if (parm.action === 'del') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该条件么吗?'})
        if (result) {
          await statusManageRequest.delCondition(this.chooseData.id)
          this.$emit('dataUpdate')
        }
      }
    },
    getSelectData (dataSourceType, dataKey) {
      let data = dataSourceType === 'user' ? this.userSourceKeys : this.dataSourceKeys
      let field = data.find(v => v.key === dataKey)
      let sData = []
      if (!field) {
        return sData
      }
      if (field.selectData) {
        sData = field.selectData
      } else if (field.tag) {
        if (field.tag === 'user') {
          sData = this.$store.getters.userList
        } else if (field.tag === 'role') {
          sData = this.$store.getters.roleList
        } else if (field.tag === 'org') {
          sData = this.$store.getters.orgList
        }
      }
      sData = sData.map(v => Object.assign({}, v, {key: String(v.key)}))
      return sData
    }
  }
}
</script>

<style scoped lang="less">
.status-c {
  width: 100%;
  height: 100%;
}
.c-table {
  width: 100%;
  height: calc(100% - 48px);
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>